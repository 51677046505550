import React, { useState } from 'react';
import { Upload, message, Button, Card, Spin, Image } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Dragger } = Upload;

const BackgroundRemover = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processedImageUrl, setProcessedImageUrl] = useState(null);
  const [processingTime, setProcessingTime] = useState(null);

  const handleUpload = async () => {
    if (!file) {
      message.error('Please select an image first!');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://apps.maida.co:8007/remove-background/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-API-Key': 'YOUR_API_KEY_HERE', // Replace with your actual API key
        },
      });

      setProcessedImageUrl(response.data.url);
      setProcessingTime(response.data.processing_time.toFixed(2));
      message.success('Background removed successfully!');
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while processing the image.');
    } finally {
      setLoading(false);
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    onRemove: () => {
      setFile(null);
      setProcessedImageUrl(null);
      setProcessingTime(null);
    },
  };

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <Card className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Background Remover by maverickphp</h1>
        <Dragger {...uploadProps} className="mb-6">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
        <div className="text-center">
          <Button 
            type="primary" 
            onClick={handleUpload} 
            disabled={!file || loading}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Remove Background
          </Button>
        </div>
        {loading && (
          <div className="text-center mt-4">
            <Spin size="large" />
            <p className="mt-2 text-gray-600">Processing your image...</p>
          </div>
        )}
        {processedImageUrl && (
          <div className="mt-6">
            <h2 className="text-xl font-semibold mb-2 text-gray-700">Processed Image</h2>
            <Image src={processedImageUrl} alt="Processed" className="w-full rounded-lg shadow" />
            <p className="mt-2 text-sm text-gray-600">Processing Time: {processingTime} seconds</p>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BackgroundRemover;